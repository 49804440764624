// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-js": () => import("/opt/build/repo/src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-executive-committee-js": () => import("/opt/build/repo/src/pages/executive-committee.js" /* webpackChunkName: "component---src-pages-executive-committee-js" */),
  "component---src-pages-get-in-touch-with-map-js": () => import("/opt/build/repo/src/pages/get-in-touch-with-map.js" /* webpackChunkName: "component---src-pages-get-in-touch-with-map-js" */),
  "component---src-pages-get-in-touch-js": () => import("/opt/build/repo/src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-objectives-js": () => import("/opt/build/repo/src/pages/objectives.js" /* webpackChunkName: "component---src-pages-objectives-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-scholarship-2022-js": () => import("/opt/build/repo/src/pages/scholarship-2022.js" /* webpackChunkName: "component---src-pages-scholarship-2022-js" */),
  "component---src-pages-scholarship-2023-js": () => import("/opt/build/repo/src/pages/scholarship-2023.js" /* webpackChunkName: "component---src-pages-scholarship-2023-js" */),
  "component---src-pages-scholarship-2024-js": () => import("/opt/build/repo/src/pages/scholarship-2024.js" /* webpackChunkName: "component---src-pages-scholarship-2024-js" */),
  "component---src-pages-scholarship-old-js": () => import("/opt/build/repo/src/pages/scholarship-old.js" /* webpackChunkName: "component---src-pages-scholarship-old-js" */),
  "component---src-pages-scholarship-js": () => import("/opt/build/repo/src/pages/scholarship.js" /* webpackChunkName: "component---src-pages-scholarship-js" */)
}

